<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">{{ isEdit ? "Edit User" : "Add New User" }}</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>

    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid">
        <v-text-field
          label="name"
          type="text"
          v-model="obj.name"
          :hide-details="true"
        >
        </v-text-field>
        <v-text-field
          class="mt-5"
          label="Password"
          type="password"
          v-model="obj.password"
          :hide-details="true"
        >
        </v-text-field>
        <v-text-field
          class="mt-5"
          label="address"
          type="text"
          v-model="obj.address"
          :hide-details="true"
        >
        </v-text-field>
        <v-select
          class="mt-5"
          label="Plan type"
          :items="plans"
          v-model="obj.plan"
          item-text="title"
          item-value="_id"
        ></v-select>
        <!-- <ViewPlan v-if="obj.plan" :item="planChoosed" showBtnSelect /> -->

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          @click="addUser"
          ><span class="white--text font-16">{{
            isEdit ? "Update" : "Done"
          }}</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
// import ViewPlan from "@/components/dialogs/ViewPlan.vue";
export default {
  components: {
    // ViewPlan,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    valid: false,
    obj: {},
    plans: [],
  }),
  computed: {
    planChoosed() {
      //console.log(this.obj.plan);
      return this.plans.find((plan) => plan._id === this.obj.plan);
    },
  },
  methods: {
    addCountHandler() {
      this.obj.numberOfProducts.push(this.count);
      this.count = "";
    },
    async addUser() {
      if (!this.valid) {
        this.$store.dispatch("showSnack", {
          text: "Please check the fields",
          color: "error",
        });
      }
      let formData = new FormData();
      if (this.obj.password) {
        formData.append("password", this.obj.password);
      }
      formData.append("name", this.obj.name);
      formData.append("address", this.obj.address);

      let res;
      if (this.isEdit) {
        res = await this.$axios.patch(
          `${process.env.VUE_APP_USERS}/${this.item._id}`,
          formData
        );
      } else {
        res = await this.$axios.post(process.env.VUE_APP_USERS, formData);
        formData.append("email", this.obj.email);
      }
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit("getData");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async getPlans() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PLANS);
      if (data) {
        this.plans = data.plans;
        //console.log("dsds", this.plans);
      }
    },
  },
  async created() {
    await this.getPlans();
    if (this.isEdit) {
      this.obj = this.item;
    }
  },
};
</script>

<style></style>
