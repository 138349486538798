<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">Change User's Plan</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>

    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid">
        <v-select
          class="mt-5"
          label="Plan type"
          :items="plans"
          v-model="obj.plan"
          item-text="title"
          item-value="_id"
        ></v-select>

        <input type="date" v-model="obj.endDate" format="YYYY-MM-DD" />

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          @click="changePlan"
          ><span class="white--text font-16">Done</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import moment from "moment";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    valid: false,
    obj: {},
    plans: [],
  }),
  methods: {
    async changePlan() {
      if (!this.valid) {
        this.$store.dispatch("showSnack", {
          text: "Please check the fields",
          color: "error",
        });
      }

      this.$axios
        .post(`${process.env.VUE_APP_SUBSCRIPTIONS}/promote/${this.item._id}`, {
          plan:
            typeof this.obj.plan === "object"
              ? this.obj.plan._id
              : this.obj.plan,
          endDate: this.obj.endDate,
        })
        .then((res) => {
          this.$store.dispatch("showSnack", {
            text: "Added Successfully",
            color: "success",
          });
          this.$emit("getData");
          this.$emit("close");
        })
        .catch((err) => {
          this.$store.dispatch("showSnack", {
            text: err.message || err.error || "Something went wrong!",
            color: "error",
          });
        });
    },
    async getPlans() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PLANS);
      if (data) {
        this.plans = data.plans;
      }
    },
  },
  async created() {
    await this.getPlans();
    this.obj = this.item;
    this.obj.endDate = this.item.planDetails.endDate
      ? moment(this.item.planDetails.endDate).format("YYYY-MM-DD")
      : "";
  },
  watch: {
    item(newVal) {
      this.obj = newVal;
      this.obj.endDate = newVal.planDetails.endDate
        ? moment(newVal.planDetails.endDate).format("YYYY-MM-DD")
        : "";
    },
  },
};
</script>

<style></style>
