<template>
  <div class="admin-page">
    <!-- header -->
    <div class="d-flex align-center justify-space-between mt-5 mr-5">
      <span class="font-32">Users</span>
      <!-- <div class="d-flex align-center cursor-pointer" @click="Addnewuser">
        <span class="font-22 font-600">New User</span>
        <span
          class="font-30 d-inline-block px-3 white--text primary ml-2 radius-5"
          >+</span
        >
      </div> -->
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      class="elevation-1 mt-5"
      mobile-breakpoint=""
      @update:page="getData"
      @update:items-per-page="getData"
      :loading="isLoading"
      :options.sync="paginationConfig"
      :server-items-length="paginationConfig.itemsLength"
    >
      <template v-slot:[`item.plan.title`]="{ item }">
        <v-btn @click="editUserPlan(item)">{{
          item.plan ? item.plan.title : "_"
        }}</v-btn>
      </template>
      <template v-slot:[`item.isVerified`]="{ item }">
        <div v-if="item.isVerified">
          <v-chip class="ma-2" color="teal" text-color="white">
            <v-avatar left>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            Verified
          </v-chip>
        </div>
        <div v-else>
          <v-chip class="ma-2" color="red" text-color="white">
            <v-avatar left>
              <!-- cross icon -->
              <v-icon> mdi-close-circle </v-icon>
            </v-avatar>
            Not Verified
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.isBlocked`]="{ item }">
        <v-switch
          class="mt-0 mr-0"
          :hide-details="true"
          v-model="item.isBlocked"
          inset
          @change="changeStatus(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <img
            @click="editHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/setting-black.svg')"
            alt=""
          />
          <img
            @click="deleteHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/delete-black.svg')"
            alt=""
          />
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="userPlanDialog" max-width="450px">
      <change-user-plan
        @close="userPlanDialog = false"
        @getData="getDataDialog"
        :item="selectedItem"
      ></change-user-plan>
    </v-dialog>

    <v-dialog v-model="userDialog" max-width="450px">
      <new-user
        @close="userDialog = false"
        @getData="getDataDialog"
        :isEdit="isEdit"
        :item="selectedItem"
      ></new-user>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-dialog
        @close="deleteDialog = false"
        :urlApi="usersApiUrl"
        :itemID="selectedItem._id"
        @getData="getData"
      ></delete-dialog>
    </v-dialog>
  </div>
</template>

<script>
import NewUser from "../../../components/dialogs/NewUser.vue";
import ChangeUserPlan from "./components/ChangeUserPlan.vue";
export default {
  components: { NewUser, ChangeUserPlan },
  data: () => ({
    userDialog: false,
    userPlanDialog: false,
    deleteDialog: false,
    totalRecord: 0,
    isLoading: false,
    headers: [
      { text: "Email", value: "email" },
      { text: "Name", value: "name" },
      { text: "Plan", value: "plan.title" },
      { text: "Verified", value: "isVerified" },
      { text: "Blocked", value: "isBlocked" },
      { text: "Actions", value: "actions", align: "center" },
    ],
    data: [],
    paginationConfig: {
      page: 1,
      itemsPerPage: 10,
      pageStart: 0,
      pageStop: 10,
      pageCount: 1,
      itemsLength: 10,
    },
    isEdit: false,
    selectedItem: {},
  }),
  computed: {
    usersApiUrl() {
      return process.env.VUE_APP_USERS;
    },
  },
  methods: {
    async getData() {
      //console.log("get data" , this.paginationConfig);
      try {
        this.isLoading = true;
        let res = await this.$axios.get(process.env.VUE_APP_USERS, {
          params: {
            pageSize:
              this.paginationConfig?.itemsPerPage < 0
                ? this.paginationConfig?.itemsLength
                : this.paginationConfig?.itemsPerPage,
            pageNumber: this.paginationConfig?.page ?? 1,
          },
        });
        if (res.status == 200) {
          this.isLoading = false;
          this.data = res.data.users;
          this.totalRecord = res.data.count;
          this.paginationConfig.itemsLength = res.data.count;
          return;
        }
        throw new Error(res?.message || "failed to fetch users");
      } catch (err) {
        this.$store.dispatch("showSnack", {
          text: err.message,
          color: "error",
        });
      }
    },
    changeCount(count) {
      //console.log(count);
      // this.$store.dispatch("changeCount", count);
    },
    async changeStatus(item) {
      try {
        let response = await this.$axios.patch(
          `${process.env.VUE_APP_USERS}/${item._id}`,
          {
            isBlocked: item.isBlocked,
          }
        );
        //console.log(response);
        if (response.status == 200) {
          this.$store.dispatch("showSnack", {
            text: item.isBlocked ? "User Blocked" : "User Unblocked",
            color: "success",
          });
        } else {
          throw new Error(response.data.message || "failed to change status");
        }
      } catch (err) {
        this.$store.dispatch("showSnack", {
          text: err.message || "Something went wrong",
          color: "error",
        });
      }
    },
    async getDataDialog() {
      //console.log("get data");
      this.userDialog = false;
      this.isEdit = false;
      await this.getData();
    },
    editHandler(item) {
      this.isEdit = true;
      this.userDialog = true;
      this.selectedItem = { ...item };
    },
    editUserPlan(item) {
      this.userPlanDialog = true;
      this.selectedItem = { ...item };
    },
    deleteHandler(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
      //console.log(item);
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteEnter(_, _2, next) {
    if (localStorage.getItem("role") == "admin") {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style lang="scss">
.admin-page {
  padding: 0px 4%;
}

.v-data-table-header {
  background: $primary;

  span {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
